<template>
  <template v-for="item of form" :key="item.name">
    <div
      v-if="item.name !== 'mobile' && item.name !== 'contact_id'"
      class="bg-[#1C1316] flex items-center px-4 py-3 my-4 rounded-lg border hover:border-[#24B6E4]"
      :class="item.error ? 'border-[#E41489]' : 'border-transparent'"
    >
      <div class="pr-4 border-r border-[#24B6E4] border-opacity-50">
        <img :src="item.icon" />
      </div>
      <input
        :type="item.inputType"
        :placeholder="item.placeholder"
        v-model="item.val"
        :maxlength="item.maxlength"
        class="bg-transparent w-full px-4 text-sm placeholder-[#909093]"
      />
      <div v-if="item.eyesShow">
        <img
          v-show="item.inputType === 'password'"
          @click="item.inputType = 'text'"
          class="w-5"
          src="@/assets/icons/sign/eyes-open.svg"
        />
        <img
          v-show="item.inputType === 'text'"
          @click="item.inputType = 'password'"
          class="w-5"
          src="@/assets/icons/sign/eyes-close.svg"
        />
      </div>
    </div>
    <div v-else class="flex items-center text-sm space-x-2 mb-2">
      <Select
        mode="dark"
        :modelVal="item.selectVal"
        :options="item.selectOptions"
        :label="item.name === 'mobile' ? 'label' : null"
        :placeholder="item.selectPlaceholder"
        @update:value="item.selectVal = $event"
        class="flex-[0.6]"
      />
      <div
        class="bg-[#1C1316] px-4 py-3 rounded-lg flex-1 border border-transparent hover:border-[#24B6E4]"
        :class="captchaCodeError ? 'border-[#E41489]' : 'border-transparent'"
      >
        <input
          v-model="item.val"
          type="text"
          class="bg-transparent w-full px-2 text-sm placeholder-[#909093]"
          :placeholder="item.placeholder"
        />
      </div>
      <p v-show="mobileError" class="text-xs text-[#E41489]">
        手机号不能为空白
      </p>
    </div>
    <p v-show="item.error" class="text-xs text-[#E41489]">
      {{ item.errorMsg }}
    </p>
    <p v-show="item.name === 'mobile'" class="text-xs text-[#24B6E4]">
      为了维护您的权利，注册姓名/身分证/帐户须为同一人。请使用本人手机号码且可接收简讯。禁止利用多重身分申请大量帐号，造成会员资料重叠或点数遗失，后果自负。
    </p>
  </template>

  <div class="flex items-center gap-6 my-4 rounded-lg overflow-hidden">
    <div
      class="bg-[#1C1316] px-4 py-2 rounded-lg flex-1 border border-transparent hover:border-[#24B6E4]"
      :class="captchaCodeError ? 'border-[#E41489]' : 'border-transparent'"
    >
      <input
        v-model="captchaCode"
        type="text"
        class="bg-transparent w-full px-2 text-sm placeholder-main-600"
        placeholder="请输入验证码"
      />
    </div>
    <div
      class="rounded-md overflow-hidden"
      style="flex: 0.5"
      @click="getCaptchaImage"
    >
      <img class="w-full" :src="captchaImage" />
    </div>
  </div>

  <p v-show="captchaCodeError" class="text-xs text-[#E41489] mb-4">
    请输入验证码
  </p>
  <div class="text-[#909093] text-xs" @click="isCheck = !isCheck">
    <van-icon
      name="checked"
      :color="isCheck ? '#24B6E4' : '#909093'"
      size="15"
      class="mr-1"
      style="float: left"
    />
    确认本人以满18岁且在此网站的活动投资行为,明确知道投资有风险,如有造成亏损本司不负责,明确澳门新葡京娱乐城,为公平公正公开平台,资料内容填写正确无误。本人也接受在此项申请下有关的<span
      class="text-[#E41489]"
      >所有规则与条例以及隐私权声明</span
    >。
  </div>
  <div
    class="bg-[#24B6E4] h-10 w-full rounded-full text-sm flex items-center justify-center mt-4"
    @click="submit"
  >
    注册
  </div>
</template>

<script setup>
import { ref } from '@vue/reactivity';
import { get_captcha_img, set_sign_up } from '@/api/main';
import { onMounted } from '@vue/runtime-core';
import { showFailToast } from 'vant';
import BankSelect from '../BankSelect.vue';
import Select from '@/components/Select.vue';
import { useStore } from 'vuex';
const store = useStore();
const emits = defineEmits(['signUpSus']);
const isCheck = ref(false);
const captchaImage = ref(null);
const captchaCode = ref('');
const captchaCodeError = ref(false);
const mobile = ref(null);
const selectOptions = ref(['WeChat', 'Line', 'Telegram', 'Whats App']);
const areaOptions = ref(['+86', '+60', '+852', '+65', '+853', '+886']);
const form = ref([
  {
    name: 'realname',
    val: '',
    inputType: 'text',
    eyesShow: false,
    placeholder: '请输入您的真实姓名',
    selectVal: true,
    error: false,
    errorMsg: '姓名不能为空白',
    maxlength: '30',
    icon: require('@/assets/icons/sign/account.svg'),
  },
  {
    name: 'mobile',
    val: '',
    inputType: 'number',
    eyesShow: false,
    placeholder: '请输入可收到简讯的手机号',
    selectName: 'country',
    selectVal: '0',
    selectOptions: store.state.phoneCountries,
    selectPlaceholder: '',
    error: false,
    errorMsg: '手机号不能为空白',
    maxlength: '20',
    icon: require('@/assets/icons/sign/phone.svg'),
  },
  {
    name: 'username',
    val: '',
    inputType: 'text',
    eyesShow: false,
    placeholder: '请输入帐号名称，需为3~20位英数组合',
    selectVal: true,
    error: false,
    errorMsg: '帐号不能为空白',
    maxlength: '20',
    icon: require('@/assets/icons/sign/account.svg'),
  },
  {
    name: 'password',
    val: '',
    inputType: 'password',
    eyesShow: true,
    placeholder: '请输入密码',
    selectVal: true,
    error: false,
    errorMsg: '密码不能为空白',
    maxlength: '30',
    icon: require('@/assets/icons/sign/password.svg'),
  },
  {
    name: 'check_password',
    val: '',
    inputType: 'password',
    eyesShow: true,
    placeholder: '请输入确认密码',
    selectVal: true,
    error: false,
    errorMsg: '确认密码不能为空白',
    maxlength: '30',
    icon: require('@/assets/icons/sign/password.svg'),
  },
  {
    name: 'contact_id',
    val: '',
    inputType: 'text',
    eyesShow: true,
    placeholder: '请输入您的联系方式',
    selectName: 'contact_type',
    selectVal: null,
    selectOptions: ['WeChat', 'Line', 'Telegram', 'Whats App'],
    selectPlaceholder: '通讯软体',
    error: false,
    errorMsg: '请选择及填写联系方式',
    icon: require('@/assets/icons/sign/password.svg'),
  },
]);

onMounted(async () => {
  await getCaptchaImage();
});
const getCaptchaImage = async () => {
  captchaImage.value = await get_captcha_img();
};
const submit = async () => {
  if (!isCheck.value) {
    showFailToast('请先同意服务条款及隐私协议');
    return;
  }
  let formData = {};
  form.value.map((e) => {
    if (e.name !== 'invitation_code') {
      e.error = !e.val || e.selectVal === null;
    }
    if (e.selectName === 'country') {
      formData[e.selectName] = e.selectOptions[e.selectVal].iso2;
    }
    if (e.selectName === 'contact_type' && e.selectVal !== null) {
      formData[e.selectName] = e.selectOptions[e.selectVal]
        .toLowerCase()
        .replace(/\s*/g, '');
    }
    formData[e.name] = e.val;
  });
  const hasError = form.value.find((e) => e.error);
  if (hasError || captchaCodeError.value) return;
  formData.captcha_code = captchaCode.value;
  captchaCodeError.value = !captchaCode.value;

  const res = await set_sign_up(formData);
  if (res) {
    emits('signUpSus');
  }
};
</script>

<style></style>
