<template>
  <div class="h-12 flex items-center px-4" @click="handelBack">
    <img class="w-5" src="@/assets/icons/back.svg" />
  </div>
  <div class="bg-[#2B2633] rounded-t overflow-y-scroll pb-4" style="height:calc(100vh - 3rem)">
    <div class="px-6 text-xl pt-8 pb-5">
      <p class=" border-l-2 border-[#24B6E4] pl-3 leading-5">{{ signType === 1 ? '登录' : '创建帐户' }}</p>

    </div>
    <div class="px-6">
      <component :is="isComponent" @signUpSus="signType = 1"></component>
    </div>
    <div class="flex text-[14px] justify-center gap-3 px-6 mt-4">
      <p>联系客服</p> /
      <p v-show="signType === 1" @click="signType = 2">立即注册</p>
      <p v-show="signType === 2" @click="signType = 1">已有帐号</p>
    </div>
  </div>
</template>

<script setup>
import { ref } from '@vue/reactivity'
import { computed } from '@vue/runtime-core'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import SignIn from '../components/Sign/SignIn.vue'
import SignUp from '../components/Sign/SignUp.vue'
const store = useStore()
const router = useRouter()
const signType = ref(1)
const isComponent = computed(() => {
  return signType.value === 1 ? SignIn : SignUp
})
const handelBack = () => {
  if (signType.value === 1) {
    router.push('/')
  } else {
    signType.value = 1
  }
}
const mainInfo = computed(() => store.state.mainInfo)
</script>

<style></style>
