<template>
  <template v-for="item of form" :key="item.name">
    <div class="bg-[#1C1316] flex items-center px-4 py-3 my-4 rounded-lg border hover:border-[#24B6E4]"
      :class="item.error ? 'border-[#E41489]' : 'border-transparent'">
      <div class="pr-4 border-r border-[#24B6E4] border-opacity-50">
        <img :src="item.icon" />
      </div>
      <input :type="item.inputType" :placeholder="item.placeholder" v-model="item.val"
        class="bg-transparent w-full px-4 text-sm placeholder-[#909093]" />
      <div v-if="item.eyesShow">
        <img v-show="item.inputType === 'password'" @click="item.inputType = 'text'" class="w-5"
          src="@/assets/icons/sign/eyes-open.svg" />
        <img v-show="item.inputType === 'text'" @click="item.inputType = 'password'" class="w-5"
          src="@/assets/icons/sign/eyes-close.svg" />
      </div>
    </div>
    <p v-show="item.error" class="text-xs text-[#E41489]">{{ item.errorMsg }}</p>
  </template>
  <a class="text-[#24B6E4] text-sm float-right mb-4" @click="showFailToast('请联系客服')">忘记密码？</a>
  <div class="bg-[#24B6E4] h-10 w-full rounded-full text-sm flex items-center justify-center mt-8" @click="submit">
    登陆
  </div>
</template>

<script setup>
import { ref } from '@vue/reactivity'
import { computed } from '@vue/runtime-core'
import { set_sign_in } from '@/api/main'
import { useStore } from 'vuex'
import { showFailToast } from 'vant';
const store = useStore()
const mainInfo = computed(() => store.state.mainInfo)
const form = ref([
  {
    name: 'username',
    val: '',
    inputType: 'text',
    eyesShow: false,
    placeholder: '请输入您的用户名',
    error: false,
    errorMsg: '用户名不能为空白',
    icon: require('@/assets/icons/sign/account.svg'),
  },
  {
    name: 'password',
    val: '',
    inputType: 'password',
    eyesShow: true,
    placeholder: '请输入密码',
    error: false,
    errorMsg: '密码不能为空白',
    icon: require('@/assets/icons/sign/password.svg'),
  },
])

const submit = async () => {
  let formData = {}
  form.value.map((e) => {
    e.error = !e.val
    return (formData[e.name] = e.val)
  })
  const hasError = form.value.find((e) => e.error)
  if (hasError) return
  await set_sign_in(formData)
}
</script>

<style></style>
